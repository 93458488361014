// source: coord.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coord.FetchGraphRequest', null, global);
goog.exportSymbol('proto.coord.FetchGraphResponse', null, global);
goog.exportSymbol('proto.coord.QUERY_TYPE', null, global);
goog.exportSymbol('proto.coord.Query', null, global);
goog.exportSymbol('proto.coord.QueryProgressRequest', null, global);
goog.exportSymbol('proto.coord.QueryProgressResponse', null, global);
goog.exportSymbol('proto.coord.QueryResult', null, global);
goog.exportSymbol('proto.coord.VertexMessage', null, global);
goog.exportSymbol('proto.coord.VertexMessages', null, global);
goog.exportSymbol('proto.coord.WorkerVertices', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.Query = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.coord.Query.repeatedFields_,
    null
  );
};
goog.inherits(proto.coord.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.Query.displayName = 'proto.coord.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.QueryResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.QueryResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.QueryResult.displayName = 'proto.coord.QueryResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.VertexMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.VertexMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.VertexMessage.displayName = 'proto.coord.VertexMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.VertexMessages = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.coord.VertexMessages.repeatedFields_,
    null
  );
};
goog.inherits(proto.coord.VertexMessages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.VertexMessages.displayName = 'proto.coord.VertexMessages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.QueryProgressRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.QueryProgressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.QueryProgressRequest.displayName =
    'proto.coord.QueryProgressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.QueryProgressResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.QueryProgressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.QueryProgressResponse.displayName =
    'proto.coord.QueryProgressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.WorkerVertices = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.coord.WorkerVertices.repeatedFields_,
    null
  );
};
goog.inherits(proto.coord.WorkerVertices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.WorkerVertices.displayName = 'proto.coord.WorkerVertices';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.FetchGraphRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.FetchGraphRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.FetchGraphRequest.displayName = 'proto.coord.FetchGraphRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coord.FetchGraphResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coord.FetchGraphResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coord.FetchGraphResponse.displayName = 'proto.coord.FetchGraphResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coord.Query.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.Query.prototype.toObject = function (opt_includeInstance) {
    return proto.coord.Query.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.Query} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.Query.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        clientid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        querytype: jspb.Message.getFieldWithDefault(msg, 2, 0),
        nodesList:
          (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
        graph: jspb.Message.getFieldWithDefault(msg, 4, ''),
        tablename: jspb.Message.getFieldWithDefault(msg, 5, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.Query}
 */
proto.coord.Query.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.Query();
  return proto.coord.Query.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.Query}
 */
proto.coord.Query.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setClientid(value);
        break;
      case 2:
        var value = /** @type {!proto.coord.QUERY_TYPE} */ (reader.readEnum());
        msg.setQuerytype(value);
        break;
      case 3:
        var value = /** @type {!Array<number>} */ (reader.readPackedUint64());
        msg.setNodesList(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setGraph(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setTablename(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.Query.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.Query.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getQuerytype();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writePackedUint64(3, f);
  }
  f = message.getGraph();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getTablename();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional string ClientId = 1;
 * @return {string}
 */
proto.coord.Query.prototype.getClientid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.setClientid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional QUERY_TYPE QueryType = 2;
 * @return {!proto.coord.QUERY_TYPE}
 */
proto.coord.Query.prototype.getQuerytype = function () {
  return /** @type {!proto.coord.QUERY_TYPE} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.coord.QUERY_TYPE} value
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.setQuerytype = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * repeated uint64 Nodes = 3;
 * @return {!Array<number>}
 */
proto.coord.Query.prototype.getNodesList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.setNodesList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.addNodes = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.clearNodesList = function () {
  return this.setNodesList([]);
};

/**
 * optional string Graph = 4;
 * @return {string}
 */
proto.coord.Query.prototype.getGraph = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.setGraph = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string TableName = 5;
 * @return {string}
 */
proto.coord.Query.prototype.getTablename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.coord.Query} returns this
 */
proto.coord.Query.prototype.setTablename = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.QueryResult.prototype.toObject = function (opt_includeInstance) {
    return proto.coord.QueryResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.QueryResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.QueryResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        query:
          (f = msg.getQuery()) &&
          proto.coord.Query.toObject(includeInstance, f),
        result: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        error: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.QueryResult}
 */
proto.coord.QueryResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.QueryResult();
  return proto.coord.QueryResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.QueryResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.QueryResult}
 */
proto.coord.QueryResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.coord.Query();
        reader.readMessage(
          value,
          proto.coord.Query.deserializeBinaryFromReader
        );
        msg.setQuery(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setResult(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setError(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.QueryResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.QueryResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.QueryResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.QueryResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(1, f, proto.coord.Query.serializeBinaryToWriter);
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeDouble(2, f);
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional Query Query = 1;
 * @return {?proto.coord.Query}
 */
proto.coord.QueryResult.prototype.getQuery = function () {
  return /** @type{?proto.coord.Query} */ (
    jspb.Message.getWrapperField(this, proto.coord.Query, 1)
  );
};

/**
 * @param {?proto.coord.Query|undefined} value
 * @return {!proto.coord.QueryResult} returns this
 */
proto.coord.QueryResult.prototype.setQuery = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.coord.QueryResult} returns this
 */
proto.coord.QueryResult.prototype.clearQuery = function () {
  return this.setQuery(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coord.QueryResult.prototype.hasQuery = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional double Result = 2;
 * @return {number}
 */
proto.coord.QueryResult.prototype.getResult = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.coord.QueryResult} returns this
 */
proto.coord.QueryResult.prototype.setResult = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional string Error = 3;
 * @return {string}
 */
proto.coord.QueryResult.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.coord.QueryResult} returns this
 */
proto.coord.QueryResult.prototype.setError = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.VertexMessage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.VertexMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.VertexMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.VertexMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sourcevertexid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        destvertexid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        value: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.VertexMessage}
 */
proto.coord.VertexMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.VertexMessage();
  return proto.coord.VertexMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.VertexMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.VertexMessage}
 */
proto.coord.VertexMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setSourcevertexid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setDestvertexid(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.VertexMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.VertexMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.VertexMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.VertexMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSourcevertexid();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getDestvertexid();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
};

/**
 * optional uint64 SourceVertexId = 1;
 * @return {number}
 */
proto.coord.VertexMessage.prototype.getSourcevertexid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.coord.VertexMessage} returns this
 */
proto.coord.VertexMessage.prototype.setSourcevertexid = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint64 DestVertexId = 2;
 * @return {number}
 */
proto.coord.VertexMessage.prototype.getDestvertexid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.coord.VertexMessage} returns this
 */
proto.coord.VertexMessage.prototype.setDestvertexid = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 Value = 3;
 * @return {number}
 */
proto.coord.VertexMessage.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.coord.VertexMessage} returns this
 */
proto.coord.VertexMessage.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coord.VertexMessages.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.VertexMessages.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.VertexMessages.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.VertexMessages} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.VertexMessages.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        vertexmessagesList: jspb.Message.toObjectList(
          msg.getVertexmessagesList(),
          proto.coord.VertexMessage.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.VertexMessages}
 */
proto.coord.VertexMessages.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.VertexMessages();
  return proto.coord.VertexMessages.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.VertexMessages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.VertexMessages}
 */
proto.coord.VertexMessages.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.coord.VertexMessage();
        reader.readMessage(
          value,
          proto.coord.VertexMessage.deserializeBinaryFromReader
        );
        msg.addVertexmessages(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.VertexMessages.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.VertexMessages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.VertexMessages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.VertexMessages.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getVertexmessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.coord.VertexMessage.serializeBinaryToWriter
    );
  }
};

/**
 * repeated VertexMessage vertexMessages = 1;
 * @return {!Array<!proto.coord.VertexMessage>}
 */
proto.coord.VertexMessages.prototype.getVertexmessagesList = function () {
  return /** @type{!Array<!proto.coord.VertexMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.coord.VertexMessage, 1)
  );
};

/**
 * @param {!Array<!proto.coord.VertexMessage>} value
 * @return {!proto.coord.VertexMessages} returns this
 */
proto.coord.VertexMessages.prototype.setVertexmessagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.coord.VertexMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coord.VertexMessage}
 */
proto.coord.VertexMessages.prototype.addVertexmessages = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.coord.VertexMessage,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coord.VertexMessages} returns this
 */
proto.coord.VertexMessages.prototype.clearVertexmessagesList = function () {
  return this.setVertexmessagesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.QueryProgressRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.QueryProgressRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.QueryProgressRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.QueryProgressRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.QueryProgressRequest}
 */
proto.coord.QueryProgressRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.QueryProgressRequest();
  return proto.coord.QueryProgressRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.QueryProgressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.QueryProgressRequest}
 */
proto.coord.QueryProgressRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.QueryProgressRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.QueryProgressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.QueryProgressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.QueryProgressRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.QueryProgressResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.QueryProgressResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.QueryProgressResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.QueryProgressResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        superstepnumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
        messagesMap: (f = msg.getMessagesMap())
          ? f.toObject(includeInstance, proto.coord.VertexMessages.toObject)
          : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.QueryProgressResponse}
 */
proto.coord.QueryProgressResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.QueryProgressResponse();
  return proto.coord.QueryProgressResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.QueryProgressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.QueryProgressResponse}
 */
proto.coord.QueryProgressResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setSuperstepnumber(value);
        break;
      case 2:
        var value = msg.getMessagesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint64,
            jspb.BinaryReader.prototype.readMessage,
            proto.coord.VertexMessages.deserializeBinaryFromReader,
            0,
            new proto.coord.VertexMessages()
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.QueryProgressResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.QueryProgressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.QueryProgressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.QueryProgressResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSuperstepnumber();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getMessagesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      2,
      writer,
      jspb.BinaryWriter.prototype.writeUint64,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.coord.VertexMessages.serializeBinaryToWriter
    );
  }
};

/**
 * optional uint64 superstepNumber = 1;
 * @return {number}
 */
proto.coord.QueryProgressResponse.prototype.getSuperstepnumber = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.coord.QueryProgressResponse} returns this
 */
proto.coord.QueryProgressResponse.prototype.setSuperstepnumber = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * map<uint64, VertexMessages> messages = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.coord.VertexMessages>}
 */
proto.coord.QueryProgressResponse.prototype.getMessagesMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<number,!proto.coord.VertexMessages>} */ (
    jspb.Message.getMapField(
      this,
      2,
      opt_noLazyCreate,
      proto.coord.VertexMessages
    )
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.coord.QueryProgressResponse} returns this
 */
proto.coord.QueryProgressResponse.prototype.clearMessagesMap = function () {
  this.getMessagesMap().clear();
  return this;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coord.WorkerVertices.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.WorkerVertices.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.WorkerVertices.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.WorkerVertices} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.WorkerVertices.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        verticesList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.WorkerVertices}
 */
proto.coord.WorkerVertices.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.WorkerVertices();
  return proto.coord.WorkerVertices.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.WorkerVertices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.WorkerVertices}
 */
proto.coord.WorkerVertices.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Array<number>} */ (reader.readPackedUint64());
        msg.setVerticesList(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.WorkerVertices.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.WorkerVertices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.WorkerVertices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.WorkerVertices.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getVerticesList();
  if (f.length > 0) {
    writer.writePackedUint64(1, f);
  }
};

/**
 * repeated uint64 vertices = 1;
 * @return {!Array<number>}
 */
proto.coord.WorkerVertices.prototype.getVerticesList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.coord.WorkerVertices} returns this
 */
proto.coord.WorkerVertices.prototype.setVerticesList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coord.WorkerVertices} returns this
 */
proto.coord.WorkerVertices.prototype.addVertices = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coord.WorkerVertices} returns this
 */
proto.coord.WorkerVertices.prototype.clearVerticesList = function () {
  return this.setVerticesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.FetchGraphRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.FetchGraphRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.FetchGraphRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.FetchGraphRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.FetchGraphRequest}
 */
proto.coord.FetchGraphRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.FetchGraphRequest();
  return proto.coord.FetchGraphRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.FetchGraphRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.FetchGraphRequest}
 */
proto.coord.FetchGraphRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.FetchGraphRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.FetchGraphRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.FetchGraphRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.FetchGraphRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.coord.FetchGraphResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.coord.FetchGraphResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.coord.FetchGraphResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.coord.FetchGraphResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        workerverticesMap: (f = msg.getWorkerverticesMap())
          ? f.toObject(includeInstance, proto.coord.WorkerVertices.toObject)
          : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coord.FetchGraphResponse}
 */
proto.coord.FetchGraphResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coord.FetchGraphResponse();
  return proto.coord.FetchGraphResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coord.FetchGraphResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coord.FetchGraphResponse}
 */
proto.coord.FetchGraphResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = msg.getWorkerverticesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint32,
            jspb.BinaryReader.prototype.readMessage,
            proto.coord.WorkerVertices.deserializeBinaryFromReader,
            0,
            new proto.coord.WorkerVertices()
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coord.FetchGraphResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.coord.FetchGraphResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coord.FetchGraphResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coord.FetchGraphResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getWorkerverticesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      1,
      writer,
      jspb.BinaryWriter.prototype.writeUint32,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.coord.WorkerVertices.serializeBinaryToWriter
    );
  }
};

/**
 * map<uint32, WorkerVertices> workerVertices = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.coord.WorkerVertices>}
 */
proto.coord.FetchGraphResponse.prototype.getWorkerverticesMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<number,!proto.coord.WorkerVertices>} */ (
    jspb.Message.getMapField(
      this,
      1,
      opt_noLazyCreate,
      proto.coord.WorkerVertices
    )
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.coord.FetchGraphResponse} returns this
 */
proto.coord.FetchGraphResponse.prototype.clearWorkerverticesMap = function () {
  this.getWorkerverticesMap().clear();
  return this;
};

/**
 * @enum {number}
 */
proto.coord.QUERY_TYPE = {
  PAGE_RANK: 0,
  SHORTEST_PATH: 1,
};

// goog.object.extend(exports, proto.coord);
module.exports = proto.coord;
